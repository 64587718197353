@use '../theme-variables.module.scss' as *;

.user-creation-container {
    height: calc(100vh - $headerHeight - 2 * $gridGapUserCreation );
    overflow-x: hidden;
    overflow-y: scroll;
}

.user-creation-header {
    position: relative;
    background-color:  var(--whiteColor);
    grid-area: 1/1/2/3;
    display: flex;
    align-items: center;
    .create-user-btn{
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
    }
}

.user-info-card{
    width: '100%';
    background-color:  var(--whiteColor);
    display: flex;
    align-items: center;
}

.close-btn{
    position: absolute;
    right: 0;
}

.user-list-wrapper{
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    overflow-y: scroll;
}

.search-fields-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1rem;
}

@media (max-width:600px) {
    .user-info-card{
        border-radius: 5px;
        margin-bottom: 1.5rem;
    }
    .divider{
        margin-top: 2rem;
        margin-bottom: 1rem;
        display: block;
        opacity: 0.2;
        display: flex;
        justify-content: center;
        hr{
            width: 90%;
        }
    }
    .action-btn-wrapper{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .info-grid{
        margin-bottom: 1.5rem;
    }
}

@media (min-width:600px) {
    .divider{
        display: none;
    }
    
    .action-btn-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.action-btns{
    margin-right: 0.5rem;
}
@media only screen and (max-width: 650px) and (min-width: 600px){
    .action-btns{
        margin-right: 0rem;
        padding: 0.5rem;
    }
}