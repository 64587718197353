//variables
$appFontSize: 1.125rem;
$headerHeight: clamp(50px, 5vh, 60px);
$sideBarWidth: clamp(50px, 5vw, 80px);
$gridGap: 25px;
$gridGapUserCreation: 22px;


$themes: (
    appBackgroundColor: #f3f3f3,
    primaryMain:#01468E,
    primaryLight:#F7A800,
    secondaryMain:#DFEFFF,
    greyColor:#A7A6A6,
    whiteColor:#ffffff,
    platinum:#E1E1E1,
    whitesmokeColor:#f3f3f3,
    buttonDisableBackgroundColor:#E1E1E1,
    downloadLink:#759AC1,
    oldSilverGrey:#838383,
    disableButtonBgColor:#D9D9D9,
    disableButtonColor:#A4A4A4,
    inprogress:#F1A634,
    completed:#26922B,
    verified:#01468E,
    rejected:#EF0202,
    active:#09B66D,
    inactive:#FF3D57,
    blackcolor:#000000,
    subtitleGrey:#939393,
    deleted:#8273F3
);

:root {
    @each $color, $value in $themes {
      --#{$color}: #{$value};
    }
  }

// Export theme colors to JS
// name of color variable will in the format - colorName+themeMode(light/dark)
  :export {

    @each $key, $value in $themes {
      #{unquote($key)}: $value;
    }
  
  }