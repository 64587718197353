@use '../theme-variables.module.scss' as *;

.grid-wrapper{
    margin: 1.5rem 0rem;
    height: calc(100vh - clamp(60px, -5vh, 70px) - 122px);
    overflow: scroll;
} 

.sma-report-container{
    background-color: var(--whiteColor);
    padding: 1.5rem;
    margin-top: 1rem;
}
.date-range{
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    font-style: italic;
    color: var(--subtitleGrey);
    font-weight: 400;
    font-size: 1rem;
}
.reports-containter{
    // height: calc(100vh - $headerHeight - 2 * $gridGap );
    // overflow-x: hidden;
    // overflow-y: scroll;
    height: 100%;
}
.reports-header {
    background-color: var(--whiteColor);
    grid-area: 1/1/2/3;
    display: flex;
    align-items: center;
}
.table-wrapper{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
}
.side-graph-container{
    overflow: scroll;
}
.main-date-range{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
}
.custom-date-wrapper{
    @media (max-width:'600px') {
        margin-top: 0.5rem;
    }
    padding: 0 20px;
    display: flex;
    justify-content: space-evenly;
}
.fullscreen-icon{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.decay-analysis-table{
    th{
            padding: 0.7rem;
            color:#000000;
            font-weight: 600;
            font-size: 1rem;
    }
    tr td{
        color:000000;
        font-weight: 600;
        font-size: 0.9rem;
    }

}