/* src/App.css */
@use '../theme-variables.module.scss' as *;

.app-container {
  display: grid;
  grid-template-columns: $sideBarWidth 1fr;
  grid-template-rows: $headerHeight 1fr;
  min-height: 100vh;
  gap: $gridGap;
  background-color: var(--appBackgroundColor);
}

.header {
  background-color: var(--primaryMain);
  grid-area: 1/1/2/3;
  z-index: 1;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  display: flex;
  align-items: center;
}

.app-logo{
  min-height: 30px;
  height: 5.5vh;
  max-height: 45px;
}


.sidebar {
  padding-top: calc($headerHeight + 5px);
  background-color: var(--WhiteColor);
  grid-area: 1/1/3/2;
  box-shadow: 2px 10px 10px 0px rgba(0, 0, 0, 0.07);
}

.main-content {
  grid-area: 2/2/3/3;
  margin: 0px $gridGap $gridGap 0px;

}

.sidebar-wrapper{
 height: calc(100vh - $headerHeight);
}

.sidebar-tabs-wrapper > div{
  display: flex;
  justify-content: center;
}

.sidebar-tabs {
  min-width: auto !important;
}