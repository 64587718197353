.drawer-block{
    padding: 0 1.5rem 0 1.5rem;
}

.drawer-head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
}

.drawer-body{
    width: 100%;
    background-color: red;
}

.drawer-list-item{
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user-grid-area{
 .MuiFormControl-root{
    width: -moz-available; // Firefox
    @supports (-webkit-appearance:none) {
      width: -webkit-fill-available; // Chrome
    }
    margin-right: 1.5rem;
    margin-bottom: 2rem;
 }   
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.custom-grid-item{
    text-align: center;
}

.ro-id-value > div {
    padding-right: 2px !important;
}